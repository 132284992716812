import { TOKEN_KEY } from 'constants/index';
import { BASE_URL, STORAGE_URL, ApiError } from 'constants/api';

const getBasicHeaders = (contentType = 'application/json') => {
  const token = localStorage.getItem(TOKEN_KEY);
  return Object.fromEntries(
    Object.entries({
      'Accept': 'application/json',
      'Content-Type': contentType,
      'Authorization': token && `Bearer ${token}`,
    }).filter(([_,v]) => v)
  );
};

const getApi = async (method, path, options) => {
  try {
    const { contentType, ...restOptions } = options;
    const baseUrl = path.startsWith('storage') ? STORAGE_URL : BASE_URL;
    const url = `${baseUrl}/api/${path}`;
    const headers = getBasicHeaders(contentType);
    const response = await fetch(url, { method, headers, ...restOptions });
    const data = await response.json();
    if (!response.ok) {
      throw new ApiError(data.error);
    }
    return data;
  } catch (err) {
    if (err instanceof ApiError || err.name === 'AbortError') {
      throw err;
    } else {
      throw new Error('Something went wrong');
    }
  }
};

export const api = {
  get: (path, ...rest) => getApi('GET', path, ...rest),
  post: (path, ...rest) => getApi('POST', path, ...rest),
  put: (path, ...rest) => getApi('PUT', path, ...rest),
  delete: (path, ...rest) => getApi('DELETE', path, ...rest),
};
