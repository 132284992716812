import React from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { USER } from 'constants/user';

const AuthGuard = ({ opposite, children }) => {

  if (!USER.current && !opposite) {
    return <Navigate to={generatePath(ROUTES.SIGN_IN)} />
  }

  if (USER.current && opposite) {
    return <Navigate to={generatePath(ROUTES.PROJECTS)} />
  }

  return (<>{children}</>);
}

export default AuthGuard;
