import { api } from 'utils/api';

const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

export const getParcelAddress = async (coords: any, options: Record<string, any> = {}) => {
  const noResult = { fullAddress: '', formattedAddress: '' };
  if (!coords) return noResult;
  const searchParams = new URLSearchParams();
  searchParams.set('access_token', process.env.REACT_APP_MAPBOX_TOKEN!);
  const data = await api.get(`proxy/${baseUrl}/${coords[0]},${coords[1]}.json?${searchParams}`, { ...options });
  const result = data.features?.[0];
  if (!result) return noResult;
  const fullAddress = result.place_name as string;
  const formattedAddress = fullAddress?.split(',').slice(0, -1).join(',');
  return { fullAddress, formattedAddress };
};

export const searchMapboxParcels = async (query: string, center?: number[], options: Record<string, any> = {}): Promise<App.AddressSearchItem[]> => {
  const searchParams = new URLSearchParams();
  searchParams.set('access_token', process.env.REACT_APP_MAPBOX_TOKEN!);
  searchParams.set('country', 'us');
  if (center) searchParams.set('proximity', center.toString());
  const data = await api.get(`proxy/${baseUrl}/${encodeURIComponent(query)}.json?${searchParams}`, { ...options });
  const suggestions = data.features.map((f: any) => {
    const title = f.text;
    const place = f.place_name;
    const placeWithoutCountry = place.split(',').slice(1, -1).join(',').trim();
    const countryName =  place.split(',').slice(-1).join('').trim();
    const description = placeWithoutCountry || countryName;
    return {
      id: String(f.id),
      title,
      description,
      address: `${title}, ${description}`,
      centroid: f.center,
    }
  });
  return suggestions;
};
