import { useDispatch as useReduxDispatch } from 'react-redux';
import { useSelector as useReduxSelector } from 'react-redux';
import { TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import app from 'store/app';

export const store = configureStore({ reducer: { app } });

export type RootState = ReturnType<typeof store.getState>;

export const useDispatch: () => typeof store.dispatch = useReduxDispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
