import { createReducer } from '@reduxjs/toolkit';
import { getSuppliersAction, getModelsAction } from 'store/app/actions';
import { detectSupplierAction, detectModelsAction, detectAddressAction } from 'store/app/actions';
import { addActiveModelAction, removeActiveModelAction, setActiveModelAction } from 'store/app/actions';
import { setSubmissionModalAction, setTOSModalAction, setPolicyModalAction, setAddressAction } from 'store/app/actions';
import { setActiveColorVariantAction, flipLayoutAction, setSatelliteOpacityAction } from 'store/app/actions';
import { setParcelDataAction, setBuildingsDataAction, setViewModeAction } from 'store/app/actions';
import { setLastPositionAction, setPositionAction, setDefaultParamsAction } from 'store/app/actions';
import { setCanShowAddressHintAction, setCanShowMoveSpaceHintAction, setCanShowMoveModelHintAction } from 'store/app/actions';
import { setShowAddressHintAction, setShowMoveSpaceHintAction, setShowMoveModelHintAction } from 'store/app/actions';
import { HIDE_HINT_SEARCH, HIDE_HINT_SPACE_CONTROLS, HIDE_HINT_MODEL_CONTROLS } from 'constants/index';

const getInitState = (): App.Root => ({
  defaultParams: null,

  suppliers: null,
  suppliersLoading: false,
  suppliersError: null,
  suppliersLoaded: false,

  models: null,
  modelsLoading: false,
  modelsError: null,
  modelsLoaded: false,

  supplier: null,
  supplierLoaded: false,

  activeModels: null,
  activeModelsLoaded: false,

  address: null,
  addressLoaded: false,

  submissionModal: null,
  TOSModal: false,
  policyModal: false,

  colorVariants: null,
  activeColorVariant: null,
  flippedLayout: null,
  satelliteOpacity: 0,
  viewMode: '3D',
  lastPosition: null,
  position: null,

  parcelData: null,
  buildingsData: null,

  canShowAddressHint: !localStorage.getItem(HIDE_HINT_SEARCH),
  canShowMoveSpaceHint: !localStorage.getItem(HIDE_HINT_SPACE_CONTROLS),
  canShowMoveModelHint: !localStorage.getItem(HIDE_HINT_MODEL_CONTROLS),
  showAddressHint: false,
  showMoveSpaceHint: false,
  showMoveModelHint: false,
});

const initialState: App.Root = getInitState();

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(detectSupplierAction, (state, action) => {
      state.supplier = action.payload;
      state.supplierLoaded = true;
    })
    .addCase(detectModelsAction, (state, action) => {
      state.activeModels = action.payload;
      state.activeModelsLoaded = true;
    })
    .addCase(detectAddressAction.fulfilled, (state, action) => {
      state.address = action.payload;
      state.addressLoaded = true;
    })
    .addCase(detectAddressAction.rejected, (state) => {
      state.address = null;
      state.addressLoaded = true;
    })
    .addCase(addActiveModelAction, (state, action) => {
      if (!state.activeModels) state.activeModels = [];
      if (!state.activeModels.some(i => i.Slug === action.payload.Slug)) {
        state.activeModels.push(action.payload);
      }
    })
    .addCase(removeActiveModelAction, (state, action) => {
      if (!state.activeModels) return;
      state.activeModels = state.activeModels.filter((
        i => i.Slug !== action.payload.Slug
      ));
    })
    .addCase(setActiveModelAction, (state, action) => {
      state.activeModels = [action.payload];
    })
    .addCase(setAddressAction, (state, action) => {
      state.address = action.payload;
    })
    .addCase(setSubmissionModalAction, (state, action) => {
      state.submissionModal = action.payload;
    })
    .addCase(setTOSModalAction, (state, action) => {
      state.TOSModal = action.payload;
    })
    .addCase(setPolicyModalAction, (state, action) => {
      state.policyModal = action.payload;
    })
    .addCase(setActiveColorVariantAction, (state, action) => {
      if (!state.activeColorVariant) state.activeColorVariant = {};
      state.activeColorVariant[action.payload.model] = action.payload.variant;
    })
    .addCase(flipLayoutAction, (state, action) => {
      if (!state.flippedLayout) state.flippedLayout = {};
      state.flippedLayout[action.payload] = !state.flippedLayout[action.payload];
    })
    .addCase(setSatelliteOpacityAction, (state, action) => {
      state.satelliteOpacity = action.payload;
    })
    .addCase(setLastPositionAction, (state, action) => {
      state.lastPosition = action.payload;
    })
    .addCase(setPositionAction, (state, action) => {
      if (!state.position) state.position = {};
      state.position[action.payload.model] = action.payload.position;
    })
    .addCase(setParcelDataAction, (state, action) => {
      state.parcelData = action.payload;
    })
    .addCase(setBuildingsDataAction, (state, action) => {
      state.buildingsData = action.payload;
    })
    .addCase(setViewModeAction, (state, action) => {
      state.viewMode = action.payload;
    })
    .addCase(setDefaultParamsAction, (state, action) => {
      state.defaultParams = action.payload;
      if (action.payload?.viewMode) state.viewMode = action.payload.viewMode as App.ViewMode;
      if (action.payload?.opacity) state.satelliteOpacity = action.payload.opacity;
      if (action.payload?.position) state.position = action.payload.position;
    })
    .addCase(setCanShowAddressHintAction, (state, action) => {
      state.canShowAddressHint = action.payload;
    })
    .addCase(setCanShowMoveSpaceHintAction, (state, action) => {
      state.canShowMoveSpaceHint = action.payload;
    })
    .addCase(setCanShowMoveModelHintAction, (state, action) => {
      state.canShowMoveModelHint = action.payload;
    })
    .addCase(setShowAddressHintAction, (state, action) => {
      state.showAddressHint = action.payload;
    })
    .addCase(setShowMoveSpaceHintAction, (state, action) => {
      state.showMoveSpaceHint = action.payload;
    })
    .addCase(setShowMoveModelHintAction, (state, action) => {
      state.showMoveModelHint = action.payload;
    })
    .addCase(getSuppliersAction.pending, (state) => {
      state.suppliersLoading = true;
      state.suppliersError = null;
    })
    .addCase(getSuppliersAction.fulfilled, (state, action) => {
      state.suppliersLoading = false;
      state.suppliers = action.payload;
      state.suppliersLoaded = true;
    })
    .addCase(getSuppliersAction.rejected, (state, action) => {
      state.suppliersLoading = false;
      state.suppliersError = action.error.message!;
      state.suppliers = null;
      state.suppliersLoaded = true;
    })
    .addCase(getModelsAction.pending, (state) => {
      state.modelsLoading = true;
      state.modelsError = null;
    })
    .addCase(getModelsAction.fulfilled, (state, action) => {
      state.modelsLoading = false;
      state.models = action.payload;
      state.modelsLoaded = true;
      const variants = state.models.map(i => (
        [i.Slug, i['glb model - Exterior Color Variants']?.split(',').map(i => '#' + i.trim())]
      )).filter(([_, v]) => v) as (string | string[])[][];
      const activeVariants = variants.map(([k, v]) => [k, v[0]])
      const flippedLayout = state.models.map(i => [i.Slug, false]);
      state.colorVariants = { ...Object.fromEntries(variants), ...state.colorVariants };
      state.activeColorVariant = { ...Object.fromEntries(activeVariants), ...state.activeColorVariant };
      state.flippedLayout = { ...Object.fromEntries(flippedLayout), ...state.flippedLayout };
    })
    .addCase(getModelsAction.rejected, (state, action) => {
      state.modelsLoading = false;
      state.modelsError = action.error.message!;
      state.models = null;
      state.modelsLoaded = true;
    });
});

export default reducer;
