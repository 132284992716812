import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getSuppliers, getModels } from 'api/airtable';
import { searchAddress } from 'api/search';
import { HIDE_HINT_SEARCH, HIDE_HINT_SPACE_CONTROLS, HIDE_HINT_MODEL_CONTROLS } from 'constants/index';

export const detectSupplierAction = createAction('app/detectSupplier', (params: App.DetectSupplierActionParams) => {
  if (!params.supplierUrl || !params.suppliers) return { payload: null };
  const supplier = params.suppliers.find(i => i.Slug === params.supplierUrl) ?? null;
  return { payload: supplier };
});

export const detectModelsAction = createAction('app/detectModels', (params: App.DetectModelsActionParams) => {
  if (!params.models) return { payload: null };
  if (!params.modelUrl) return { payload: [params.models[0]].filter(Boolean) };
  const slugs = params.modelUrl.split(',');
  const models = params.models.filter(i => slugs.includes(i.Slug!));
  return { payload: models.length ? models : [params.models[0]].filter(Boolean) };
});

export const detectAddressAction = createAsyncThunk('app/detectAddress', async (params: App.DetectAddressActionParams, thunk) => {
  if (!params.addressUrl) return null;
  const [a, coords] = params.addressUrl.split('@');
  const suggestions = await searchAddress(coords ?? a.replace(/-/g, ' '));
  const address = suggestions[0] ?? null;
  return address;
});

export const addActiveModelAction = createAction('app/addActiveModel', (model: App.Model) => {
  return { payload: model };
});

export const removeActiveModelAction = createAction('app/removeActiveModel', (model: App.Model) => {
  return { payload: model };
});

export const setActiveModelAction = createAction('app/setActiveModel', (model: App.Model) => {
  return { payload: model };
});

export const setAddressAction = createAction('app/setAddress', (address: App.Root['address']) => {
  return { payload: address };
});

export const setSubmissionModalAction = createAction('app/setSubmissionModal', (model: App.Root['submissionModal']) => {
  return { payload: model };
});

export const setTOSModalAction = createAction('app/setTOSModal', (state: App.Root['TOSModal']) => {
  return { payload: state };
});

export const setPolicyModalAction = createAction('app/setPolicyModal', (state: App.Root['policyModal']) => {
  return { payload: state };
});

export const setActiveColorVariantAction = createAction('app/setActiveColorVariant', (params: App.SetActiveColorVariantActionParams) => {
  return { payload: params };
});

export const flipLayoutAction = createAction('app/flipLayout', (model: string) => {
  return { payload: model };
});

export const setSatelliteOpacityAction = createAction('app/setSatelliteOpacity', (opacity: number) => {
  return { payload: opacity };
});

export const setParcelDataAction = createAction('app/setParcelData', (data: App.Root['parcelData']) => {
  return { payload: data };
});

export const setBuildingsDataAction = createAction('app/setBuildingsData', (data: App.Root['buildingsData']) => {
  return { payload: data };
});

export const setViewModeAction = createAction('app/setViewMode', (mode: App.Root['viewMode']) => {
  return { payload: mode };
});

export const setLastPositionAction = createAction('app/setLastPosition', (position: App.Root['lastPosition']) => {
  return { payload: position };
});

export const setPositionAction = createAction('app/setPosition', (params: App.SetPositionActionParams) => {
  return { payload: params };
});

export const setDefaultParamsAction = createAction('app/setDefaultParams', (params: App.Root['defaultParams']) => {
  return { payload: params };
});

export const setCanShowAddressHintAction = createAction('app/setCanShowAddressHint', (state: boolean) => {
  localStorage.setItem(HIDE_HINT_SEARCH, 'TRUE');
  return { payload: state };
});

export const setCanShowMoveSpaceHintAction = createAction('app/setCanShowMoveSpaceHint', (state: boolean) => {
  localStorage.setItem(HIDE_HINT_SPACE_CONTROLS, 'TRUE');
  return { payload: state };
});

export const setCanShowMoveModelHintAction = createAction('app/setCanShowMoveModelHint', (state: boolean) => {
  localStorage.setItem(HIDE_HINT_MODEL_CONTROLS, 'TRUE');
  return { payload: state };
});

export const setShowAddressHintAction = createAction('app/setShowAddressHint', (state: boolean) => {
  return { payload: state };
});

export const setShowMoveSpaceHintAction = createAction('app/setShowMoveSpaceHint', (state: boolean) => {
  return { payload: state };
});

export const setShowMoveModelHintAction = createAction('app/setShowMoveModelHint', (state: boolean) => {
  return { payload: state };
});

export const getSuppliersAction = createAsyncThunk('app/getSuppliers', async () => {
  const suppliers = await getSuppliers();
  return suppliers;
});

export const getModelsAction = createAsyncThunk('app/getModels', async () => {
  const models = await getModels();
  return models;
});
