import BezierCurve from 'mapbox-gl-draw-bezier-curve-mode/src/lib/utils/BezierCurve';
import BezierNode from 'mapbox-gl-draw-bezier-curve-mode/src/lib/utils/BezierNode';
import { PARCEL_GENERATED_DATA_KEY, PARCEL_EDITOR_DATA_KEY, PARCEL_EDITOR_CACHE_KEY } from 'constants/index';

const turf = window.turf;

export const generateData = (formData, objects, sideLines, sideIndeces, parcelArea, primaryArea) => {
  const d = formData;
  const { front, back, left, right } = d;

  const data = {
    geometry: { objects, sideLines, sideIndeces },
    attributes: {
      address: d.address,
      distance_from_primary_building: d.distance_from_primary_building,
      distance_from_secondary_building: d.distance_from_secondary_building,
      side_offset: { front, back, left, right },
      maximum_size: d.maximum_size,
      maximum_size_description: d.maximum_size_description,
      maximum_height: d.maximum_height,
      design_standards_fields: d.design_standards_fields.map(i => i.value),
      zone_code: d.zone_code,
      distance_to_public_transit_station: d.distance_to_public_transit_station,
      parcel_area: parcelArea,
      primary_building_area: primaryArea,
      zone_fields: d.zone_fields.map(i => i.value),
      plans: d.plans,
      name: d.name,
      status: d.status,
      link: d.link,
      building_type: d.building_type,
    }
  };

  return data;
};

export const generateParcelData = (formData, objects, sideLines, sideIndeces, parcelArea, primaryArea) => {
  const d = formData;
  const { front, back, left, right } = d;

  const data = {
    geometry: { objects, sideLines, sideIndeces },
    attributes: {
      address: d.address,
      distance_from_primary_building: d.distance_from_primary_building,
      distance_from_secondary_building: d.distance_from_secondary_building,
      maximum_size: d.maximum_size,
      zone_code: d.zone_code,
      building_type: d.building_type,
      side_offset: { front, back, left, right },
      parcel_area: parcelArea,
      primary_building_area: primaryArea,
    }
  };

  return data;
};

export const adaptPolygonsForEditor = (polygons) => {
  return polygons.map((f, i) => {
    const polygon = turf.cleanCoords(turf.polygon(f.geometry.coordinates));
    const line = turf.polygonToLine(polygon);
    polygon.id = i + 1;
    line.id = polygon.id;
    polygon.properties.line = line;
    polygon.properties.type = i === 0 ? 'parcel' : i === 1 ? 'primary' : 'secondary';
    line.properties = { bezierGroup: { bezierCurves: [new BezierCurve()] } };
    line.properties.bezierGroup.bezierCurves[0].verts = line.geometry.coordinates;
    line.properties.bezierGroup.bezierCurves[0].nodes = line.geometry.coordinates.map(c => new BezierNode(c));
    return polygon;
  });
}

export const saveParcelEditorInfo = (map, parcelInfo) => {
  const id = parcelInfo?.parcel?.properties.id ?? Date.now();
  const center = map.getCenter().toArray();
  const zoom = map.getZoom();
  const objects = (() => {
    if (!parcelInfo) return [];
    const toPolygon = f => f?.geometry.type === 'MultiPolygon' ? turf.flatten(f).features[0] : turf.polygon(f.geometry.coordinates);
    const parcel = toPolygon(parcelInfo.parcel);
    const buildings = parcelInfo.building.features?.sort((a, b) => a.properties.type - b.properties.type).map(f => toPolygon(f)) ?? [];
    return adaptPolygonsForEditor([parcel, ...buildings].filter(Boolean));
  })();
  const meta = { id, center, zoom };
  const editorData = { meta, objects };
  localStorage.setItem(PARCEL_EDITOR_DATA_KEY, JSON.stringify(editorData));
  localStorage.removeItem(PARCEL_EDITOR_CACHE_KEY);
  localStorage.removeItem(PARCEL_GENERATED_DATA_KEY);
}
