import React, { useEffect } from 'react';
import useViewport from 'hooks/useViewport';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

const Layout: React.FC<Props> = ({ children }) => {
  const viewport = useViewport();

  useEffect(() => {
    const vw = viewport.size.width * 0.01;
    const vh = viewport.size.height * 0.01;
    document.documentElement.style.setProperty('--width', `${viewport.size.width}px`);
    document.documentElement.style.setProperty('--height', `${viewport.size.height}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.body.classList[viewport.isMobileDevice ? 'add' : 'remove']('is-mobile-device');
    document.body.classList[viewport.isTabletDevice ? 'add' : 'remove']('is-tablet-device');
    document.body.classList[viewport.isTouchDevice ? 'add' : 'remove']('is-touch-device');
    document.body.classList[viewport.isPortrait ? 'add' : 'remove']('is-portrait');
    document.body.classList[viewport.isLandscape ? 'add' : 'remove']('is-landscape');
    document.body.classList[viewport.isMobileSize ? 'add' : 'remove']('is-mobile-size');
    document.body.classList[viewport.isMobile ? 'add' : 'remove']('is-mobile');
  }, [viewport]);

  return <>{children}</>;
};

export default Layout;
