import { CURRENT_SUPPLIER, SUPPLIERS } from 'constants/supplier';

const ROUTES = {
  ROOT: '/:model?/:address?',
  SUPPLIER_ROOT: '/c/:supplier/:model?/:address?',
  MAP_EDITOR: '/editor/:address?/:projectId?',
  MAP_VIEWER: '/viewer/:address?/:projectId?',
  PARCEL_EDITOR: '/parcel-editor',
  CONFIGURATOR_ROOT: CURRENT_SUPPLIER === SUPPLIERS.DWELLITO ? '/configurator' : '/',
  CONFIGURATOR_VIEWER: CURRENT_SUPPLIER === SUPPLIERS.DWELLITO ? '/configurator/:adu' : '/:adu',
  CONFIGURATOR_SUMMARY: CURRENT_SUPPLIER === SUPPLIERS.DWELLITO ? '/configurator/:adu/summary' : '/:adu/summary',
  SHORT_LINK: '/s/:short',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up/:invitation',
  PROJECTS: '/projects',
  USERS: '/users',
  MODELS: '/models',
  SEARCHES: '/property-searches',
  OTHER: '*',
};

export default ROUTES;
