const PREFIX = 'DWELLITO_';

export const METERS_TO_FEET = 3.280839895;
export const KILOMETERS_TO_FEET = METERS_TO_FEET * 1000;
export const INCHES_TO_METERS = 0.0254;
export const TOKEN_KEY = PREFIX + 'TOKEN';
export const PARCEL_EDITOR_DATA_KEY = PREFIX + 'PARCEL_EDITOR_DATA';
export const PARCEL_EDITOR_CACHE_KEY = PREFIX + 'PARCEL_EDITOR_CACHE';
export const PARCEL_GENERATED_DATA_KEY = PREFIX + 'PARCEL_TEMP_DATA';
export const INIT_SEARCH_PARAMS = { v: {} };

export const iS_DEVELOPMENT = process.env.REACT_APP_NODE_ENV === 'development';

export const HIDE_HINT_SEARCH = PREFIX + 'HIDE_HINT_SEARCH';

export const HIDE_HINT_SPACE_CONTROLS = PREFIX + 'HIDE_HINT_SPACE_CONTROLS';

export const HIDE_HINT_MODEL_CONTROLS = PREFIX + 'HIDE_HINT_MODEL_CONTROLS';
