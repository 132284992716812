import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthCheck from 'components/Auth/AuthCheck';
import AuthGuard from 'components/Auth/AuthGuard';
import Layout from 'components_/Layout';
import ROUTES from 'constants/routes';
import { CURRENT_SUPPLIER, SUPPLIERS } from 'constants/supplier';
import { store } from 'store';

/* temporary. update title for suppliers */
document.title = CURRENT_SUPPLIER === SUPPLIERS.DWELLITO ? 'Site Planner | Zoning and Design Analysis' : 'Configurator';

const Main = lazy(() => import('pages_/Main'));
const Map = lazy(() => import('pages/Map'));
const EditorAdmin = lazy(() => import('pages/EditorAdmin'));
const EditorUser = lazy(() => import('pages/EditorUser'));
const Signin = lazy(() => import('pages/Signin'));
const Signup = lazy(() => import('pages/Signup'));
const Projects = lazy(() => import('pages/Projects'));
const Models = lazy(() => import('pages/Models'));
const Searches = lazy(() => import('pages/Searches'));
const Users = lazy(() => import('pages/Users'));
const Configurator = lazy(() => import('pages/Configurator'));
const ConfiguratorViewer = lazy(() => import('pages/ConfiguratorViewer'));
const ConfiguratorSummary= lazy(() => import('pages/ConfiguratorSummary'));
const ShortLink = lazy(() => import('pages/ShortLink'));

const App = () => {
  return (
    <Provider store={store}>
      <Layout>
        <AuthCheck>
          <BrowserRouter>
            <Routes>
              {CURRENT_SUPPLIER === SUPPLIERS.DWELLITO && (
                <>
                  <Route path={ROUTES.ROOT} element={<Suspense><Main /></Suspense>} />
                  <Route path={ROUTES.SUPPLIER_ROOT} element={<Suspense><Main /></Suspense>} />
                  <Route path={ROUTES.PROJECTS} element={<AuthGuard><Suspense><Projects /></Suspense></AuthGuard>} />
                  <Route path={ROUTES.MODELS} element={<AuthGuard><Suspense><Models /></Suspense></AuthGuard>} />
                  <Route path={ROUTES.SEARCHES} element={<AuthGuard><Suspense><Searches /></Suspense></AuthGuard>} />
                  <Route path={ROUTES.USERS} element={<AuthGuard><Suspense><Users /></Suspense></AuthGuard>} />
                  <Route path={ROUTES.MAP_EDITOR} element={<AuthGuard><Suspense><EditorAdmin /></Suspense></AuthGuard>} />
                  <Route path={ROUTES.MAP_VIEWER} element={<Suspense><Map /></Suspense>} />
                  <Route path={ROUTES.PARCEL_EDITOR} element={<Suspense><EditorUser /></Suspense>} />
                  <Route path={ROUTES.SIGN_IN} element={<Suspense><AuthGuard opposite><Signin /></AuthGuard></Suspense>} />
                  <Route path={ROUTES.SIGN_UP} element={<Suspense><AuthGuard opposite><Signup /></AuthGuard></Suspense>} />
                </>
              )}
              <Route path={ROUTES.CONFIGURATOR_ROOT} element={<Suspense><Configurator /></Suspense>} />
              <Route path={ROUTES.CONFIGURATOR_VIEWER} element={<Suspense><ConfiguratorViewer /></Suspense>} />
              <Route path={ROUTES.CONFIGURATOR_SUMMARY} element={<Suspense><ConfiguratorSummary /></Suspense>} />
              <Route path={ROUTES.SHORT_LINK} element={<Suspense><ShortLink /></Suspense>} />
              <Route path={ROUTES.OTHER} element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </AuthCheck>
      </Layout>
    </Provider>
  );
}

export default App;
