import { useState, useEffect, useCallback } from 'react';
import MobileDetect from 'mobile-detect';
import { MOBILE_MIN_WIDTH } from 'constants/app';

interface Rect {
  width: number;
  height: number;
}

const useViewport = () => {
  const [isMobileDevice, setMobileDevice] = useState(false);
  const [isTabletDevice, setTabletDevice] = useState(false);
  const [isTouchDevice, setTouchDevice] = useState(false);
  const [isPortrait, setPortrait] = useState(false);
  const [isLandscape, setLandscape] = useState(false);
  const [isMobileSize, setMobileSize] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [size, setSize] = useState<Rect>({ width: 0, height: 0 });

  const getSize = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return { width, height };
  }, []);

  const getDevice = useCallback(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    const hasTouchScreen = window.matchMedia('(pointer: coarse)').matches;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const isLandscape = !isPortrait;
    return [
      Boolean(md.mobile()),
      Boolean(md.tablet()),
      hasTouchScreen,
      isPortrait,
      isLandscape,
    ];
  }, []);

  const onResize = useCallback(() => {
    const [mobile, tablet, touch, portrait, landscape] = getDevice();
    const size = getSize();
    setMobileDevice(mobile);
    setTabletDevice(tablet);
    setTouchDevice(touch);
    setPortrait(portrait);
    setLandscape(landscape);
    setMobileSize(size.width <= MOBILE_MIN_WIDTH);
    setMobile(mobile || size.width <= MOBILE_MIN_WIDTH);
    setSize(size);
  }, [getDevice, getSize]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [onResize]);

  return {
    isMobileDevice,
    isTabletDevice,
    isTouchDevice,
    isPortrait,
    isLandscape,
    isMobileSize,
    isMobile,
    size,
  };
};

export default useViewport;
