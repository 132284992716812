import { api } from 'utils/api';

export const getPlans = (options = {}) => {
  return api.get('airtable/plans', { ...options });
};

export const getCompanies = (options = {}) => {
  return api.get('airtable/companies', { ...options });
};

export const getModels = (options = {}) => {
  return api.get('airtable/models', { ...options }) as Promise<App.Model[]>;
};

export const getSuppliers = (options = {}) => {
  return api.get('airtable/suppliers', { ...options }) as Promise<App.Supplier[]>;
};

export const sendSubmission = (params: App.SubmissionParams, options = {}) => {
  const query = new URLSearchParams(params as any).toString();
  return api.get(`proxy/https://hooks.zapier.com/hooks/catch/5485468/260m9fk?${query}`, { ...options });
};
