export const SUPPLIERS: Record<string, string> = {
  LIMLIVING: 'LIMLIVING',
  DWELLITO: 'DWELLITO',
};

export const SUPPLIER_REGEX: Record<string, string> = {
  LIMLIVING: 'limliving.com',
  DWELLITO: '',
}

export const CURRENT_SUPPLIER = (() => {
  for (const [k, v] of Object.entries(SUPPLIERS)) {
    if (
      new RegExp(SUPPLIER_REGEX[k], 'i').test(window.location.host) ||
      new RegExp(SUPPLIER_REGEX[k], 'i').test(process.env.REACT_APP_SUPPLIER!)
    ) {
      return v;
    }
  }
  return SUPPLIERS.DWELLITO;
})();
