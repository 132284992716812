import { api } from 'utils/api';

export const getAuthStatus = (options = {}) => {
  return api.get('auth/status', { ...options });
};

export const signUp = async (invitation, login, password, options = {}) => {
  const body = JSON.stringify({ login, password });
  return api.post(`auth/sign_up/${invitation}`, { body, ...options });
};

export const signIn = async (login, password, options = {}) => {
  const body = JSON.stringify({ login, password });
  return api.post(`auth/sign_in`, { body, ...options });
};
