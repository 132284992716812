export const USER: { current: any } = {
  current: null,
};

export const USER_ROLE = {
  ADMIN: 1,
  CLIENT: 2,
  USER: 3,
};

export const USER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  REGISTERED: 2,
  REQUESTRED: 3,
};

export const USER_STATUS_LABEL = {
  [USER_STATUS.INACTIVE]: 'Inactive',
  [USER_STATUS.ACTIVE]: 'Active',
  [USER_STATUS.REGISTERED]: 'Registered',
  [USER_STATUS.REQUESTRED]: 'Invited',
};
