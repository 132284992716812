import { api } from 'utils/api';
import { adaptPolygonsForEditor } from 'utils/editor';

const turf = (window as any).turf;

export const getRegridParcel = async (center: any, options: Record<string, any> = {}) => {
  const searchParams = new URLSearchParams();
  searchParams.set('token', process.env.REACT_APP_REGRID_TOKEN!);
  searchParams.set('lat', center.lat);
  searchParams.set('lon', center.lng);
  searchParams.set('radius', String(10));
  searchParams.set('limit', String(10));
  const data = await api.get(`proxy/https://app.regrid.com/api/v2/parcels/point?${searchParams}`, { ...options });
  const hasParcels = (data.parcels?.features?.length ?? 0) > 0;
  if (hasParcels) {
    const parcel = data.parcels.features[0];
    const parcels = parcel.geometry.type === 'MultiPolygon' ? turf.flatten(parcel).features : [parcel];
    const biggestParcel = parcels
      .map((p: any) => ({ ...p, properties: { ...p.properties, area: turf.area(p) } }))
      .sort((a: any, b: any) => b.properties.area - a.properties.area)
      [0];
    const buildings = (data.buildings?.features ?? [])
      .flatMap((b: any) => b.geometry.type === 'MultiPolygon' ? turf.flatten(b).features : [b])
      .map((b: any) => ({ ...b, properties: { ...b.properties, area: turf.area(b) } }))
      .sort((a: any, b: any) => b.properties.area - a.properties.area);

    const objects = adaptPolygonsForEditor([biggestParcel, ...buildings]);
    return objects;
  } else {
    return [];
  }
};

export const searchRegridParcels = async (query: string, options: Record<string, any> = {}): Promise<App.AddressSearchItem[]> => {
  const coordsRegex = /^[-+]?[0-9]*(\.[0-9]+)*\s*,\s*[-+]?[0-9]*(\.[0-9]+)*$/ig;
  const idRegex = /^#.*$/ig;
  const apiRequest = coordsRegex.test(query) ? 'point' : idRegex.test(query) ? 'apn' : 'address';
  const isPoint = apiRequest === 'point';
  const isAPN = apiRequest === 'apn';
  const isAddress = apiRequest === 'address';
  const searchParams = new URLSearchParams();
  searchParams.set('token', process.env.REACT_APP_REGRID_TOKEN!);
  searchParams.set('limit', String(isPoint ? 1 : 5));
  if (isPoint) {
    const coords = query.split(',').map(i => i.trim());
    searchParams.set('lat', coords[0]);
    searchParams.set('lon', coords[1]);
    searchParams.set('radius', String(10));
  } else if (isAPN) {
    searchParams.set('parcelnumb', query.slice(1));
  } else if (isAddress) {
    searchParams.set('query', query);
  }
  const data = await api.get(`proxy/https://app.regrid.com/api/v2/parcels/${apiRequest}?${searchParams}`, { ...options });
  const suggestions = data.parcels.features.map((f: any) => ({
    id: String(f.id),
    title: f.properties.headline,
    description: f.properties.context.headline,
    address: `${f.properties.headline}, ${f.properties.context.headline}`,
    centroid: [
      parseFloat(f.properties.fields.lon),
      parseFloat(f.properties.fields.lat),
    ],
  }));
  return suggestions;
};
