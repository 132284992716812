import React, { useState, useEffect } from 'react';
import { USER } from 'constants/user';
import { getAuthStatus } from 'api/auth';

const AuthGuard = ({ children }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      if (['Signin', 'Signup'].includes(e.data)) {
        setChecked(false);
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    }
  }, []);

  useEffect(() => {
    if (checked) return;
    (async () => {
      try {
        const { user } = await getAuthStatus();
        USER.current = user;
      } catch (err) {
        USER.current = null;
      } finally {
        setChecked(true);
      }
    })();
  }, [checked]);

  return (<>{checked && children}</>);
}

export default AuthGuard;
